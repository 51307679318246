export const CHECK_AUTH = "CHECK_AUTH";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export const REQUEST_ERROR = "REQUEST_ERROR";

export const REQUEST_SUCCESS = "REQUEST_SUCCESS";

export const LOADING_START = "LOADING_START";
