import React from "react";
import { Container } from "reactstrap";
import Header from "../components/Header";

const Policy = props => (
	<div className="page-content">
		<Header title={"Privacy Policy"} />
		<div className="content-center">
			<Container>
				<div>
					<div>
						<h2>Who we are</h2>
						<p>Our website address is: https://cutone.org.</p>
						<p>
							LC Associates is an energy consulting company that provides a full
							range of energy services including:
						</p>
						<ol>
							<li>Energy Consulting and Advisory Services</li>
							<li>Strategic Energy Procurement Services</li>
							<li>Energy Incentives and Rebates Consulting</li>
							<li>Demand Response Services</li>
						</ol>
						<h2>What is Green Connect Entrepreneur Portal?</h2>
						<p>
							It is a software developed by LC Associates to uncover deep energy
							savings. It was developed for the reason that human eyes, and
							expert minds, typically cannot observe Deep Energy Savings without
							data visualization. It allows electric utility Clients to monitor
							energy KPI's and act on them. GreenConnect will aide in the
							identification of hidden inefficiencies so energy professionals
							can offer solutions to reduce energy costs or generate revenue.
						</p>
						<h2>What personal data we collect and why we collect it</h2>
						<p>
							The Customer Data Privacy Policy was initiated for the benefit of
							our customers, to ensure the protection of customer-specific
							energy use data and personally identifiable information (PII). The
							goal is to strike a reasonable balance between the collection, use
							or disclosure of any customer information by LC Associates while
							facilitating safe and reliable energy service, complying with
							legislative and regulatory mandates and meeting customers’
							expectations regarding the collection, disclosure and use of their
							data and personal information.
						</p>
						<p>
							LC Associates’ privacy policy is reviewed on a periodic basis.
							When an update is made, a notice of LC Associate’s privacy policy
							will be prominently posted on the www.cutone.org website.
						</p>
						<p>
							LC Associates collects customer information for the purposes of
							providing energy services and other products. Customer Information
							examples include, but are not limited to:
						</p>
						<ul>
							<li>
								Contact information that allows us to communicate with you,
								including your name, address, telephone number and email
								address.
							</li>
							<li>
								Billing and payment information, including your payment data,
								credit history, driver’s license or state ID number and Social
								Security Number.
							</li>
							<li>
								Electric and natural gas use data gathered by our metering
								systems such as ccf, mcf, therms, dth, kW, kWh, voltage, var, or
								power factor, and other data that is recorded by the electric or
								natural gas meter.
							</li>
							<li>
								Information gathered when you choose to participate in company
								programs or services, such as bill payment assistance, renewable
								energy, demand-side management, load management or energy
								efficiency.
							</li>
							<li>
								Anonymized website cookie data for the purposes of analysis on
								how visitors use the LC Associates website.
							</li>
						</ul>
						<h3>Comments</h3>
						<p>
							When visitors leave comments on the site we collect the data shown
							in the comments form, and also the visitor’s IP address and
							browser user agent string to help spam detection.
						</p>
						<p>
							An anonymized string created from your email address (also called
							a hash) may be provided to the Gravatar service to see if you are
							using it. The Gravatar service privacy policy is available here:
							https://automattic.com/privacy/. After approval of your comment,
							your profile picture is visible to the public in the context of
							your comment.
						</p>
						<h3>Media</h3>
						<p>
							If you upload images to the website, you should avoid uploading
							images with embedded location data (EXIF GPS) included. Visitors
							to the website can download and extract any location data from
							images on the website.
						</p>
						<h3>Contact forms</h3>
						<h3>Cookies</h3>
						<p>
							If you leave a comment on our site you may opt-in to saving your
							name, email address and website in cookies. These are for your
							convenience so that you do not have to fill in your details again
							when you leave another comment. These cookies will last for one
							year.
						</p>
						<p>
							If you have an account and you log in to this site, we will set a
							temporary cookie to determine if your browser accepts cookies.
							This cookie contains no personal data and is discarded when you
							close your browser.
						</p>
						<p>
							When you log in, we will also set up several cookies to save your
							login information and your screen display choices. Login cookies
							last for two days, and screen options cookies last for a year. If
							you select “Remember Me”, your login will persist for two weeks.
							If you log out of your account, the login cookies will be removed.
						</p>
						<p>
							If you edit or publish an article, an additional cookie will be
							saved in your browser. This cookie includes no personal data and
							simply indicates the post ID of the article you just edited. It
							expires after 1 day.
						</p>
						<h3>Embedded content from other websites</h3>
						<p>
							Articles on this site may include embedded content (e.g. videos,
							images, articles, etc.). Embedded content from other websites
							behaves in the exact same way as if the visitor has visited the
							other website.
						</p>
						<p>
							These websites may collect data about you, use cookies, embed
							additional third-party tracking, and monitor your interaction with
							that embedded content, including tracing your interaction with the
							embedded content if you have an account and are logged in to that
							website.
						</p>
						<h3>Analytics</h3>
						<h2>Who we share your data with</h2>
						<p>
							LC Associates will not disclose your information to third parties
							without your written consent, with the following exceptions:
						</p>
						<ul>
							<li>
								When it is necessary or related to our provision of energy
								consulting, demand response or energy procurement, LC Associates
								may disclose your information to Agents and Contractors working
								on behalf of the company without first obtaining your consent.
							</li>
							<li>
								LC Associates will disclose your information as directed or
								required by any warrants, court orders, subpoena, or regulatory
								requirement. The provision of such information will be
								reasonably limited to the amount authorized by law or reasonably
								necessary to fulfill a request compelled by law.
							</li>
							<li>
								LC Associates may provide third parties with aggregated and
								non-identifiable data, containing general characteristics of a
								customer group, which is used for analysis, reporting or program
								designs.
							</li>
							<li>
								Consumers Energy will not sell your information, except in
								connection with sales of certain aged receivables to third party
								collection firms for purposes of removing this liability from
								its accounts.
							</li>
						</ul>
						<h2>How long we retain your data</h2>
						<p>
							If you leave a comment, the comment and its metadata are retained
							indefinitely. This is so we can recognize and approve any
							follow-up comments automatically instead of holding them in a
							moderation queue.
						</p>
						<p>
							For users that register on our website (if any), we also store the
							personal information they provide in their user profile. All users
							can see, edit, or delete their personal information at any time
							(except they cannot change their username). Website administrators
							can also see and edit that information.
						</p>
						<h2>What rights you have over your data</h2>
						<p>
							If you have an account on this site, or have left comments, you
							can request to receive an exported file of the personal data we
							hold about you, including any data you have provided to us. You
							can also request that we erase any personal data we hold about
							you. This does not include any data we are obliged to keep for
							administrative, legal, or security purposes.
						</p>
						<p>
							LC Associates will only keep customer information for as long as
							is necessary for the purpose(s) for which it was originally
							collected.
						</p>
						<h3>Where we send your data</h3>
						<p>
							Your data is sent to third parties including vendors, agents,
							contractors, or affiliates that provide service to or on behalf of
							LC Associates
						</p>
						<h3>How we protect your data</h3>
						<p>
							We use industry-standard administrative, technical, and physical
							security measures to ensure the integrity of our systems and to
							protect your information from unauthorized access, destruction or
							alteration. Such protection measures include an enterprise
							security program based upon industry standard frameworks, security
							awareness for employees, dedicated team to detect and respond to
							threats, and collaboration with peers, state and federal partners.
						</p>
						<h3>What third parties we receive data from</h3>
						<p>
							We receive data from third party information systems and incentive
							program administrators such as the National Weather Services,
							ConEdison, the New York Independent System Operator
						</p>
					</div>
				</div>
			</Container>
		</div>
	</div>
);

export default Policy;
