import React from "react";
import { Container } from "reactstrap";
import Header from "../components/Header";
const Policy = props => (
	<>
		<div className="page-content">
			<Header title={"Privacy Policy"} />
			<div className="content-center">
				<Container>
					<div>
						<div className="texto">
							<h2>Agreement</h2>
							<p>
								By signing up to be an Affiliate in the LC Associates – Green
								Connect Entrepreneur Portal Program (“Program”) you are agreeing
								to be bound by the following terms and conditions (“Terms of
								Service”).
							</p>
							<p>
								<strong>
									LC Associates – Green Connect Entrepreneur Portal
								</strong>{" "}
								reserves the right to update and change the Terms of Service
								from time to time without notice. Any new features that augment
								or enhance the current Program, including the release of new
								tools and resources, shall be subject to the Terms of Service.
								Continued use of the Program after any such changes shall
								constitute your consent to such changes.
							</p>
							<p>
								Violation of any of the terms below will result in the
								termination of your Account. You agree to use the Green Connect
								Entrepreneur Portal at your own risk.
							</p>

							<h2>Account Terms</h2>

							<ul>
								<li>
									You must be 18 years or older to be part of this Program.
								</li>
								<li>You must live in the United States.</li>
								<li>
									You must be a human. Accounts registered by "bots" or other
									automated methods are not permitted.
								</li>
								<li>
									You must provide your legal full name, a valid email address,
									and any other information requested in order to complete the
									signup process.
								</li>
								<li>
									Your login may only be used by one person – a single login
									shared by multiple people is not permitted.
								</li>
								<li>
									You are responsible for maintaining the security of your
									account and password. <br />
									<strong>
										LC Associates – Green Connect Entrepreneur Portal
									</strong>
									&nbsp;&nbsp;cannot and will not be liable for any loss or
									damage from your failure to comply with this security
									obligation.
								</li>
								<li>
									You are responsible for all Content posted and activity that
									occurs under your account.
								</li>
								<li>
									One person or legal entity may not maintain more than one
									account.
								</li>
								<li>
									You may not use the Green Connect Entrepreneur Portal for any
									illegal or unauthorized purpose. You must not, in the use of
									the Service, violate any laws in your jurisdiction (including
									but not limited to copyright laws)
								</li>
								<li>
									You may not use the Green Connect Entrepreneur Portal to earn
									money on your own
									<strong>
										LC Associates – Green Connect Entrepreneur Portal
									</strong>{" "}
									product accounts.
								</li>
							</ul>

							<h2>Compliance with Laws</h2>

							<p>
								As a condition to your contractual relationships in the Program,
								you agree you will comply with all laws, ordinances, rules,
								regulations, orders, licenses, permits, judgments, decisions or
								other requirements of any governmental authority that has
								jurisdiction over you, whether those laws, etc. are now in
								effect or later come into effect during the contract time you
								are a Program participant. Without limiting the foregoing
								obligation, you agree that as a condition of your participation
								in the Program you will comply with all applicable laws
								(federal, state or otherwise) that govern marketing email,
								including without limitation, the CAN-SPAM Act of 2003 and all
								other anti-spam laws.
							</p>

							<h2>Term of the Agreement and Program</h2>

							<p>
								The term of this Agreement will begin upon our acceptance of
								your Program application and will end when terminated by either
								party. Either you or we may terminate this Agreement at any
								time, with or without cause, by giving the other party written
								notice of termination. Upon the termination of this Agreement
								for any reason, you will immediately cease use of&nbsp;all other
								materials provided by or on behalf of us to you pursuant hereto
								or in connection with the Program.{" "}
								<strong>
									LC Associates – Green Connect Entrepreneur Portal
								</strong>{" "}
								reserves the right to end the Program at any time.
							</p>

							<h2>Termination</h2>

							<p>
								<strong>
									LC Associates – Green Connect Entrepreneur Portal
								</strong>
								, in its sole discretion, has the right to suspend or terminate
								your account and refuse any and all current or future use of the
								Program, or any other{" "}
								<strong>
									LC Associates – Green Connect Entrepreneur Portal
								</strong>
								, for any reason at any time. Such termination of the Service
								will result in the deactivation or deletion of your Account or
								your access to your Account, and the forfeiture and
								relinquishment of all potential or to-be-paid commissions in
								your Account if they were earned through fraudulent, illegal, or
								overly aggressive, questionable sales or marketing methods.{" "}
								<strong>
									LC Associates – Green Connect Entrepreneur Portal
								</strong>{" "}
								<strong></strong> reserves the right to refuse service to anyone
								for any reason at any time.
							</p>

							<h2>Limitations of Liability</h2>

							<p>
								We will not be liable for indirect, special, or consequential
								damages (or any loss of revenue, profits, or data) arising in
								connection with this Agreement or the Program, even if we have
								been advised of the possibility of such damages.&nbsp;
							</p>

							<h2>Disclaimers</h2>

							<p>
								We make no express or implied warranties or representations with
								respect to the Program or any products sold through the Program
								(including, without limitation, warranties of fitness,
								merchantability, noninfringement, or any implied warranties
								arising out of a course of performance, dealing, or trade
								usage). In addition, we make no representation that the
								operation of the{" "}
								<strong>
									LC Associates – Green Connect Entrepreneur Portal
								</strong>
								&nbsp;will be uninterrupted or error-free, and we will not be
								liable for the consequences of any interruptions or errors.
							</p>

							<h2>Independent Investigation</h2>

							<p>
								YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO
								ALL ITS TERMS AND CONDITIONS. YOU UNDERSTAND THAT WE MAY AT ANY
								TIME (DIRECTLY OR INDIRECTLY) SOLICIT CUSTOMER REFERRALS ON
								TERMS THAT MAY DIFFER FROM THOSE CONTAINED IN THIS AGREEMENT OR
								OPERATE WEB SITES THAT ARE SIMILAR TO OR COMPETE WITH YOUR WEB
								SITE. YOU HAVE INDEPENDENTLY EVALUATED THE DESIRABILITY OF
								PARTICIPATING IN THE PROGRAM AND ARE NOT RELYING ON ANY
								REPRESENTATION, GUARANTEE, OR STATEMENT OTHER THAN AS SET FORTH
								IN THIS AGREEMENT.
							</p>

							<h2>Miscellaneous</h2>

							<p>
								This Agreement will be governed by the laws of The United
								States, without reference to rules governing choice of laws. You
								may not assign this Agreement, by operation of law or otherwise,
								without our prior written consent. Subject to that restriction,
								this Agreement will be binding on, inure to the benefit of, and
								be enforceable against the parties and their respective
								successors and assigns. Our failure to enforce your strict
								performance of any provision of this Agreement will not
								constitute a waiver of our right to subsequently enforce such
								provision or any other provision of this Agreement.
							</p>

							<p>
								The failure of{" "}
								<strong>
									LC Associates – Green Connect Entrepreneur Portal
								</strong>{" "}
								to exercise or enforce any right or provision of the Terms of
								Service shall not constitute a waiver of such right or
								provision. The Terms of Service constitutes the entire agreement
								between you and{" "}
								<strong>
									LC Associates – Green Connect Entrepreneur Portal
								</strong>{" "}
								and govern your use of the Service, superseding any prior
								agreements between you and{" "}
								<strong>
									LC Associates – Green Connect Entrepreneur Portal
								</strong>{" "}
								(including, but not limited to, any prior versions of the Terms
								of Service).
							</p>
						</div>
					</div>
				</Container>
			</div>
		</div>
	</>
);

export default Policy;
